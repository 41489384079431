<template>
  <MoveListComponent />
</template>

<script>
import store from '@/store';
import MoveListComponent from '../components/move-list/MoveListComponent.vue';

export default {
  name: 'MoveList',
  components: {
    MoveListComponent,
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['auth/getPermissions'].can_view_move_list_page) {
      next();
      return;
    }
    next(from);
  },
};
</script>

<style scoped>
</style>
