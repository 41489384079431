<template>
  <VDialog
    content-class="cross__dialog"
    v-model="visibility"
    max-width="600px"
    :persistent="changed"
    @click:outside="showConfirmDialog"
    scrollable
    :transition="$dialogTransition"
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="font-20 font-weight-bold" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
          $t("landing.btn.editRequest")
        }}</span>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <div class="text-center" v-if="loading">
            <VProgressCircular :size="50" color="primary" indeterminate></VProgressCircular>
          </div>
          <div class="" v-else>
            <VRow>
              <VCol cols="12" md="6">
                <VSelect
                  outlined
                  dense
                  :items="states"
                  :no-data-text="$t('table.noData')"
                  :label="$t('form.status')"
                  :item-value="'id'"
                  :item-text="getStatusText"
                  color="success"
                  item-color="success"
                  v-model="task.id_state"
                  :error-messages="stateIdErrors"
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                  <template v-slot:item="{ item }">
                    <div class="d-flex align-center justify-content-start ">
                      <StatusCircle :status-name="item.id" class="pb-1" />
                      <div class="elispsis">
                        {{ getStatusText(item) }}
                      </div>
                    </div>
                  </template>
                </VSelect>
              </VCol>
              <VCol cols="12" md="12">
                <v-autocomplete
                  outlined
                  dense
                  :label="$t('moveList.item')"
                  :no-data-text="$t('table.noData')"
                  :items="products"
                  :item-value="'uuid'"
                  :item-text="'product_details.name'"
                  v-model="task.uuid_item"
                  :error-messages="countErrors"
                  hide-details="auto"
                  small-chips
                  deletable-chips
                  :loading="loadingProducts"
                  :disabled="loadingProducts"
                  color="success"
                />
              </VCol>
              <VCol cols="12" md="12">
                <v-autocomplete
                  outlined
                  dense
                  :label="$t('moveList.where')"
                  :no-data-text="$t('table.noData')"
                  :items="cells"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  chips
                  v-model="task.uuid_dest_cell"
                  :error-messages="uuidDestCellErrors"
                  hide-details="auto"
                  :loading="loadingCells"
                  :disabled="loadingCells"
                  color="success"
                  item-color="success"
                >
                  <template slot="selection" slot-scope="data">
                    <VChip small close @click:close="task.uuid_dest_cell = ''">
                      {{ `${data.item.name}` }}
                    </VChip>
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ `${data.item.name}` }}
                  </template>
                </v-autocomplete>
              </VCol>
              <VCol cols="12">
                <VTextarea
                  outlined
                  dense
                  :label="$t('form.description')"
                  required
                  no-resize
                  counter
                  maxlength="255"
                  rows="2"
                  v-model="task.description"
                  color="success"
                ></VTextarea>
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 container">
          <VCol cols="6">
            <VBtn
              class="text-white text-transform-none br-10 w-100"
              dark
              @click="showConfirmDialog"
              block
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              block
              @click="onUpdate"
              :disabled="!isChanged"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.update") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { minValue, required } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import depotService from "../../services/request/depot/depotService";
import EventBus from "../../events/EventBus";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import { mapGetters, mapActions } from "vuex";
import * as getterTypes from "@/store/modules/moveList/types/getters";
import * as actionTypes from "@/store/modules/moveList/types/actions";

export default {
  name: "EditMoveListItemDialog",
  mixins: [language, notifications, validationMixin],
  validations: {
    task: {
      count: { required, minValue: minValue(1) },
      uuid_dest_cell: { required },
      uuid_item: { required },
      id_state: { required }
    }
  },
  components: {
    ConfirmLeftDialog,
    StatusCircle
  },
  methods: {
    ...mapActions("moveList", {
      fetchCells: actionTypes.FETCH_CELLS,
      fetchProducts: actionTypes.FETCH_PRODUCTS
    }),
    getStatusText({ name }) {
      return this.$t(`moveList.${name}`);
    },
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    async onUpdate() {
      this.$v.$touch();
      // orderDialog(this.task.count)
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("uuid", this.task.uuid);

          const changedData = this.$getChangedData(
            this.task,
            this.inputTask
          );
          changedData.forEach((data) => {
            formData.append(data.field, data.value);
          });
          await depotService.updateMoveListItem(formData);
          this.loading = false;
          this.setSuccessNotification(this.$t("moveList.move_list_was_update_successfully"));
          this.visibility = false;
          EventBus.$emit("move-list-item-updated");
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    }
  },
  watch: {
    task: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.task, this.inputTask);
      }
    },
    role: "updateRoleInfo"
  },
  data: () => ({
    loading: false,
    visibleConfirm: false,
    changed: false,
    task: {
      count: "",
      uuid_dest_cell: "",
      description: "",
      uuid_item: "",
      id_state: "",
      uuid: ""
    },
    loadingCells: false,
    loadingProducts: false
  }),
  async mounted() {

    this.loadingCells = true;
    await this.fetchCells();
    this.loadingCells = false;
    this.loadingProducts = true;
    await this.fetchProducts();
    this.loadingProducts = false;

    this.task.id_state = this.item.id_state === "0" ? "1" : this.item.id_state;
    this.task.count = this.item.items_amount;
    // orderDialog(this.item)
    this.task.uuid_dest_cell = this.item.uuid_dest_cell;
    this.task.description = this.item.description;
    this.task.uuid_item = this.item.uuid_item;
    this.task.uuid = this.item.uuid;
    this.inputTask = {
      ...this.task
    };
    // orderDialog(this.task)
    // orderDialog(this.inputTask)
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    item: {
      required: true
    },
    states: {
      required: true
    }
  },
  computed: {
    ...mapGetters("moveList", {
      cells: getterTypes.GET_CELLS,
      products: getterTypes.GET_PRODUCTS
    }),
    isChanged() {
      if (
        this.task.description == this.item.description &&
        this.task.uuid_dest_cell == this.item.uuid_dest_cell &&
        this.task.uuid_item == this.item.uuid_item &&
        this.task.id_state == this.item.id_state
      ) {
        return false;
      } else {
        return true;
      }
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
    countErrors() {
      const errors = [];
      if (!this.$v.task.count.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.count.required && errors.push(this.$t("form.errors.AmountRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.count.minValue &&
        errors.push(`${this.$t("table.amount")} ${this.$t("form.errors.AtLeastOne")}`);
      return errors;
    },
    uuidItemErrors() {
      const errors = [];
      if (!this.$v.task.uuid_item.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.uuid_item.required && errors.push(this.$t("form.erros.ItemRequired"));
      return errors;
    },
    stateIdErrors() {
      const errors = [];
      if (!this.$v.task.id_state.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.id_state.required && errors.push(this.$t("form.errors.StatusRequired"));
      return errors;
    },
    uuidDestCellErrors() {
      const errors = [];
      if (!this.$v.task.uuid_dest_cell.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.task.uuid_dest_cell.required && errors.push(this.$t("form.errors.WhereRequired"));
      return errors;
    }
  }
};
</script>

<style scoped></style>
