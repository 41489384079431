<template>
  <div>
    <VCard class="mb-2">
    <div class="d-flex justify-content-space-between align-center">
      <VRow no-gutters align="center"  justify="start"  >
      <VCol cols="auto" class="px-2"><span class="font-weight-medium text-h6 mr-1">{{ $t("menu.move_list") }}</span></VCol>
      <VCol cols="auto" md="6" lg="4" sm="8"  xs="8" v-if="permissions.can_search" >
        <v-autocomplete
          dense
          :items="depots"
          :no-data-text="$t('table.noData')"
          :label="$t('form.storage')"
          :item-value="'uuid'"
          :item-text="'name'"
          deletable-chips
          small-chips
          prepend-inner-icon="mdi-warehouse"
          solo
          hide-details
          @change="onChangeDeport"
        />
      </VCol>
      </VRow>
      <div class="d-flex align-center">
      <VCol class="auto pr-0" v-if="permissions.can_clear_move_list">
        <VBtn
          :disabled="!items.length"
          @click="onClickClear"
          class="error-bg text-white text-transform-none br-10"
        ><VIcon   :left="!$vuetify.breakpoint.xs">mdi-delete-sweep</VIcon>
         <div v-if="!$vuetify.breakpoint.xs">
          {{$t('btn.clear')}}
          </div></VBtn>
      </VCol>
      <VCol cols="auto" md="auto" sm="auto" class="text-right py-2">
        <VBtn

          depressed
          class="success-bg text-white text-transform-none br-10"
          dark
          @click="createTaskDialog = true"
        >
          <VIcon   :left="!$vuetify.breakpoint.xs" >mdi-plus</VIcon>
          <div v-if="!$vuetify.breakpoint.xs">
            {{$t('btn.create_apply')}}
          </div>
        </VBtn>
      </VCol>
      </div>
    </div>
    <VDivider  class="mt-2" />
    <MoveListItems
       @changedFilter="changedFilter"
      :items="items"
      :states="states"
      @delete="onDeleteItem"
    />
    <div v-observer="{nextPage:nextPage}" class="observer" v-if="!loadMoreDisabled && !loading"></div>
    <div v-if="!items || !items.length">
      <h2 class="text-center">{{$t('table.noData')}}</h2>
    </div>
    <div v-if="isLoadingFilter" class="w-100 text-center">
     <v-progress-circular
      indeterminate
      color="primary"

    ></v-progress-circular>
    </div>
    <AddMoveListItemDialog
      :visible="createTaskDialog"
      @close="createTaskDialog = false"
      v-if="createTaskDialog"
    />
    </VCard>

      <ConfirmDialog
      :visible="visibleDelete"
      @close="visibleDelete = false"
      @onConfirm="onClearList();visibleDelete = false;"
      @onCancel="visibleDelete = false"
      :typeAlert="'error'"
    >
    <template v-slot:header>
      {{$t('confirmDialog.accept_deleteTitle')}}
    </template>
    <template v-slot:description>
    {{$t('confirmDialog.accept_deleteDesc')}}
    </template>
    </ConfirmDialog>
     </div>
</template>

<script>
import MoveListItems from './MoveListItems.vue';
import depotService from '../../services/request/depot/depotService';
import language from '../../mixins/language';
import notifications from '../../mixins/notifications';
import loader from '../../mixins/loader';
import AddMoveListItemDialog from './AddMoveListItemDialog.vue';
import EventBus from '../../events/EventBus';
import user from '../../mixins/user';
import SizeUi from "../../mixins/SizeUi";
import ConfirmDeleteDialog from '../dialog/ConfirmDeleteDialog.vue';
import paginationMixin from '../../mixins/paginationMixin';
import ConfirmDialog from '../dialog/ConfirmDialog.vue';
import { mapGetters, mapActions } from "vuex";
import * as getterTypes from "@/store/modules/moveList/types/getters";
import * as actionTypes from "@/store/modules/moveList/types/actions";
import * as actionType from "@/store/modules/pageLoadTime/types/actions";
import * as getterType from "@/store/modules/pageLoadTime/types/getters";

export default {
  name: 'MoveListComponent',
  mixins: [language, loader, notifications, user,SizeUi,paginationMixin],
  components: {
    MoveListItems,
    AddMoveListItemDialog,
    ConfirmDeleteDialog,
    ConfirmDialog,
  },
  data: () => ({
    items: [],
    states: [],
    state: 0,
    deport_uuid: 0,
    createTaskDialog: false,
    isLoadingFilter:false,
    loadMoreDisabled:true,
    timeOut:0,
    params:[],
    visibleDelete:false,
    loading:false,
    page:0,
    loadTime: 0
  }),
  watch:{
      loadMoreDisabled(e){

      }
  },
  methods: {
    ...mapActions("moveList", {
      fetchDeports: actionTypes.FETCH_DEPORTS
    }),
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionType.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    onClickClear(){
      this.visibleDelete = true
    },
    async onClearList(){
      try{
        await depotService.clearMoveList();
        this.visibleDelete = false
        this.getItems()

      }catch(e){
        this.setErrorNotification(e)
      }
    },
    async changedFilter(params,next = false){
          try {
            this.loading = true
            this.page = 0
            this.params = params
            params.order = 'DESC'
            params.orderby = 'time_created'
            params.uselike = true
            params.limit = this.perPage
            params.offset = this.page * this.perPage

        const newItems = await depotService.getMoveListItemsDetails(params);
          if (next) {
            this.items.push(...newItems);
        } else {
          this.items = newItems;
        }
        this.loading = false
        if(newItems){
          this.loadMoreDisabled = newItems.length < this.perPage;
        }else{
          this.loadMoreDisabled = true
        }
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
      async emitPageChange() {
        try {
          await this.getItems({},true,true);
        } catch (e) {
          console.log(e)
        }
    },
      async getItems(params = {},next = false,isLoadingLine = false,updated) {
      try {
        this.loading = true
        if(!isLoadingLine){
          this.setLoading(true);
        }else{
          this.isLoadingFilter = true
        }
        params.offset = updated ? 0 : this.page * this.perPage;
        params.limit = updated ? this.largeLimit : this.perPage
        const newItems = await depotService.getMoveListItemsDetails({
          ...params,
          order: 'DESC',
          orderby: 'time_created',
        });
        if(isLoadingLine){
            this.isLoadingFilter = false
        }
        if (next) {
          this.items.push(...newItems);
        } else {
          this.items = newItems;
        }
        if(!next){
          this.states = await depotService.getAllStates({
            type: 'MOVE_LIST_ITEM',
          });
        }
        if (this.states) {
          if (this.states.length) {
            [this.state] = this.states;
          }
        }
        if(!isLoadingLine){
          this.setLoading(false);
        }
        this.loading = false


        if(newItems){
          this.loadMoreDisabled = newItems.length < this.perPage;
        }else{
          this.loadMoreDisabled = true
        }
      } catch (e) {
        this.loading = false
        if(!isLoadingLine){
          this.setLoading(false);
        }
        console.log(e)
      }
    },
    async onDeleteItem(uuid) {
      try {
        this.setLoading(true);
        await depotService.deleteMoveListItemDetail(uuid);
        this.setSuccessNotification(this.$t('moveList.item_was_successfully_deleted'));
        this.setLoading(false);
        await this.getItems();
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    async onChangeState(id) {
      this.state = id;
      await this.getItems({
        id_state: id,
        uuid_deport: this.deport_uuid,
      });
    },
    async onChangeDeport(uuid) {
      this.deport_uuid = uuid;
      await this.getItems({
        id_state: this.state.id,
        uuid_deport: uuid,
      });
    },
    onParams() {
      const params = [];
      if (this.state.id > 0) {
        params.push({
          id_state: this.state.id,
        });
      }
      if (this.deport_uuid > 0) {
        params.push({
          uuid_deport: this.deport_uuid,
        });
      }
      return params;
    },
  },
  computed:{
    ...mapGetters("moveList", {
      depots: getterTypes.GET_DEPORTS
    }),
    ...mapGetters('pageLoadTime', {
      startTime: getterType.GET_START_TIME,
    }),
    nextPageDisabled() {
      return this.items.length < this.perPage;
    }
  },
  async mounted() {


    this.setLoading(true);
    if(this.permissions.can_search){
      try {
       await this.fetchDeports()
    } catch (e) {
      console.log(e)
    }
      }
    EventBus.$on('move-item-created', async () => {
      this.page = 0
      await this.getItems({
        ...this.onParams,
      });
    });
    EventBus.$on('move-item-deleted', async () => {
      await this.getItems({
        ...this.onParams,
      });
    });
    EventBus.$on('move-list-item-updated', async () => {
      await this.getItems({
        ...this.onParams,
      },false,false,true);
    });
      await this.getItems();
    this.checkLoadTime();
  },
};
</script>

<style scoped>

</style>
