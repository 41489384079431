<template>
  <v-simple-table class="my-table" :dense="!isLarge">
    <template v-slot:default>
      <thead>
          <tr>
            <th class="text-left pt-8">
        <drag-icon :width="22" :height="22" :view-box-value="'0 0 26 26'" />
          </th>
          <th class="text-center icon">
            <VIcon dense >mdi-cogs</VIcon>
          </th>
            <th class="text-left">
              <div class="header">
                <VTextField
                dense
                hide-details
                solo
                :placeholder="$t('table.ns_code')"
                v-model="filter.item_details__ns_code"
                clearable
                @keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
                />
              </div>
            </th>
            <th class="text-left">
              <div class="header">
                <VTextField
                dense
                hide-details
                solo
                :placeholder="$t('table.name')"
                v-model="filter.product_details__name "
                clearable
                @keypress="validationForTitle($event)"
                @paste="validationForTitle($event)"
                />
              </div>
            </th>
            <th class="text-left">
              <div class="header">
                  <VTextField
                  dense
                  hide-details
                  solo
                  :placeholder="$t('table.option')"
                  v-model="filter.option_details__name "
                  clearable
                  />
              </div>
            </th>
            <th class="text-left">
              <div class="header">
                  <VTextField
                  dense
                  hide-details
                  solo
                  :placeholder="$t('table.departure')"
                  v-model="filter.source_cell_details__name"
                  clearable
                  />
              </div>
            </th>
            <th class="text-left">
              <div class="header">
                  <VTextField
                  dense
                  hide-details
                  solo
                  :placeholder="$t('table.appointment')"
                  v-model="filter.dest_cell_details__name"
                  clearable
                  />
              </div>
            </th>
            <th class="text-left">
              <div class="header">

                  <VSelect
                  dense
                  :items="states"
                  :item-text="getStatusText"
                  :item-value="'id'"
                  hide-details
                  solo
                  :placeholder="$t('table.state')"
                  v-model="filter.id_state"
                  clearable
                  >
                     <template v-slot:item="{ item }">
                        <div class="d-flex align-center justify-content-start ">
                              <StatusCircle :status-name="item.id" class="pb-1"  />
                                <div class="elispsis">
                                    {{ getStatusText(item) }}
                                </div>
                            </div>

                  </template>
                  </VSelect>
              </div>
            </th>

          </tr>
        </thead>
      <tbody class="move-list-items" v-if="items || items.length" name="fade" is="transition-group">
          <MoveListItem
            v-for="item in items"
            :key="item.uuid"
            :item="item"
            :states="states"
            @delete="$emit('delete', item.uuid)"
            :class="{'active-row':activeRow == item.uuid}"
            @toggleRow="toggleRow"
          />
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import _ from "lodash";
import MoveListItem from './MoveListItem.vue';
import SizeUi from "../../mixins/SizeUi";
import StatusCircle from '@/components/move-list/StatusCircle.vue';
import {
  validationForNumbers, validationForTitle
} from "@/mixins/helpers";

export default {
  name: 'MoveListItems',
  components: {
    MoveListItem,
    StatusCircle
  },
  mixins:[SizeUi],
  data:()=>({
  activeRow:'',
  filter: {
      ns_code: '',
      product_details__name: '',
      option_details__name: '',
      source_cell_details__name: '',
      dest_cell_details__name: '',
      id_state: ''
  }
  }),
  methods:{
    validationForTitle,
    validationForNumbers,
    getStatusText({name}){
      return this.$t(`moveList.${name}`)
    },
    toggleRow(e){
      this.activeRow = e
    },
       onScanerField: _.debounce(function() {
            this.changeFilter()
      }, 500),

      changeFilter(){
          const params = {
          uselike: true,
        };
        Object.keys(this.filter).forEach((key) => {
          if (this.filter[key]) {
            params[key] = this.filter[key];
          }
        });
        this.$emit('changedFilter',params)
      },

  },
  mounted(){
  },
  watch:{
     filter: {
      deep: true,
      async handler(e) {

        if(!e.item_details__ns_code){
          this.changeFilter()
        } else {
          this.onScanerField()
        }

        // }
      },
    },
},
  props: {
    items: {
      required: true,
    },
    states: {
      required: true,
    },
  },
  computed: {
    dragOptions() {
      return {
        disabled: false,
        ghostClass: 'ghost',
        handle: '.handle',
      };
    },
  },
};
</script>

