<template>
  <tr >
    <td>
      <VIcon  class="cursor-grab " :dense="!isLarge">mdi-dots-grid</VIcon>
    </td>
    <td  >
      <div class="d-flex align-center justify-content-around">
      <VBtn
         x-small
        class="btn-edit"
        depressed
        small
        color="blue"
        dark
        :class="{'sm' : !isLarge}"
        @click="onEditItem(item.uuid)"
      >
        <VIcon>mdi-file-document-edit-outline</VIcon>
      </VBtn>
      <VBtn icon color="red" :small="!isLarge" dense :class="{'ml-1' : !isLarge}" v-if="permissions.can_delay_move_list" @click="deleteItem">
      <VIcon
      >
        mdi-delete
      </VIcon>
      </VBtn>
      </div>
    </td>
    <td >{{ item.ns_code || '--' }}</td>
    <td >{{ item.product_details.name || '--' }}</td>
    <td >{{ item.option_details.name || '--' }}</td>
    <td > <VIcon class="mr-1" :size="isLarge ? '30' :'25'">mdi-table-merge-cells</VIcon>{{ item.source_cell_details.name || "--"}}</td>
    <td><VIcon class="mr-1" :size="isLarge ? '30' :'25'">mdi-table-merge-cells</VIcon>{{ item.dest_cell_details.name || "--"}}</td>
    <td>
      <div class="d-flex align-center justify-content-start ">
      <StatusCircle :status-name="item.id_state" :class="{'sm' : !isLarge}" />
      {{  $t(`moveList.${getStateName(item.id_state)}`) }}
      </div>
    </td>

    <ConfirmDialog
      :visible="deleteDialog"
      @close="deleteDialog = false"
      @onConfirm="onDelete();deleteDialog = false;"
      @onCancel="deleteDialog = false"
      :typeAlert="'error'"
    >
    <template v-slot:header>
      {{$t('confirmDialog.accept_deleteTitle')}}
    </template>
    <template v-slot:description>
    {{$t('confirmDialog.accept_deleteDesc')}}
    </template>
    </ConfirmDialog>

    <EditMoveListItemDialog
      v-if="editDialog"
      :visible="editDialog"
      @close="editDialog = false"
      :item="item"
      :states="states"
    />
  </tr>
</template>

<script>
import ConfirmDeleteDialog from '../dialog/ConfirmDeleteDialog.vue';
import EventBus from '../../events/EventBus';
import user from '../../mixins/user';
import depotService from '../../services/request/depot/depotService';
import EditMoveListItemDialog from './EditMoveListItemDialog.vue';
import StatusCircle from './StatusCircle.vue';
import SizeUi from "../../mixins/SizeUi";
import ConfirmDialog from '../dialog/ConfirmDialog.vue';

export default {
  name: 'MoveListItem',
  mixins: [user,SizeUi],
  components: {
    ConfirmDeleteDialog,
    EditMoveListItemDialog,
    StatusCircle,
    ConfirmDialog
},
  props: {
    item: {
      required: true,
    },
    states: {
      required: true,
    },
  },
  data: () => ({
    deleteDialog: false,
    editDialog: false,
  }),
  methods: {
    onEditItem(e) {
      this.$emit('toggleRow',e)
      this.editDialog = true;
    },
    async onDelete() {
      this.deleteDialog = false;
      await depotService.deleteMoveListItem(this.item.uuid);
      EventBus.$emit('move-item-deleted');
    },
    getStateById(id) {
      return this.states.find((item) => item.id === id);
    },
    getStateName(id) {
      const state = this.getStateById(id);
      return state?.name ? state?.name : 'New Input Cell';
    },
    deleteItem() {
      this.deleteDialog = true;
    },
  },
  mounted() {
  },
    computed: {
    dragOptions() {
      return {
        disabled: false,
        ghostClass: 'ghost',
        handle: '.handle',
      };
    },
  },
};
</script>

<style lang="scss">
.ghost {
  opacity: 0.5;
  background: gray !important;
}
.status-badge {
  color: var(--v-green-base);
  cursor: pointer;
  margin-top: 4px;
  user-select: none;
}
</style>
